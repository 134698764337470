.s-testimonials__head {
  margin-top: 2rem;
  margin-bottom: 6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1rem;
}

.s-testimonials__head__title {
  font-size: 2.75rem;
  line-height: 3.5rem;
  letter-spacing: -0.0113636364em;
}

.s-testimonials__button-bottom {
  display: none;
}

.s-testimonials__list {
  margin-bottom: 5rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  -moz-column-gap: 2.75rem;
  -webkit-column-gap: 2.75rem;
  column-gap: 2.75rem;
  row-gap: 4.125rem;
}

.s-testimonials__list__feedback-img {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 4rem;
  min-height: 4rem;
  max-width: 8rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.s-testimonials__list__feedback-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.s-testimonials__list__feedback-title {
  display: none;
}

.s-testimonials__list__feedback-text {
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  display: inline-block;
  line-height: 1.5rem;
  opacity: 0.72;
  letter-spacing: 0.015625em;
}

.s-testimonials__list__feedback-underline {
  overflow-wrap: break-word;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.03em;
  opacity: 0.72;
}

.s-testimonials__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.s-testimonials__footer-text {
  line-height: 1.5rem;
  letter-spacing: 0.015625em;
}

@media (max-width: 79.9988em) {
  .s-testimonials__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 47.9988em) {
  .s-testimonials__head {
    margin-bottom: 2rem;
  }

  .s-testimonials__head__title {
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: 0;
  }

  .s-testimonials__button-top {
    display: none;
  }

  .s-testimonials__button-bottom {
    margin-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
  }

  .s-testimonials__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-bottom: 2rem;
    gap: 2rem;
  }

  .s-testimonials__list__feedback-img {
    margin-bottom: 1rem;
  }

  .s-testimonials__list__feedback-title {
    margin-bottom: 0.75rem;
    display: block;
    font-family: InterMedium;
    line-height: 1.5rem;
  }

  .s-testimonials__list__feedback-text {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
  }

  .s-testimonials__footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}